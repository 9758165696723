import axios from 'axios'

const api = 'estadisticas/kpi'

const kpi = {
    firstSection: params => axios.get(`${api}/first-section`,{ params }),
    secondSection: params => axios.get(`${api}/second-section`,{ params }),
    thirdSection: params => axios.get(`${api}/third-section`,{ params }),
    fourthSection: params => axios.get(`${api}/fourth-section`,{ params }),
    fifthSection: params => axios.get(`${api}/fifth-section`,{ params }),
    sixthSection: params => axios.get(`${api}/sixth-section`,{ params }),
    seventhSection: params => axios.get(`${api}/seventh-section`,{ params }),
    eighthSection: params => axios.get(`${api}/eighth-section`,{ params }),
    inventario2: params => axios.get(`${api}/inventario2`,{ params }),
}

export default kpi
